:root{
    --primary-color : #1976d2;
    --secondary-color : #ccc;
    --table-background : #2f3245;
}
/* Custom Global Css */
/* width */
.full-width{width:100%}
.width-70{width: 70%;}
/* Custom Badge Title  */
.custom-badge-title{
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    top: 6%;
    left: -4%;
    padding: 1px 20px;
    color: #1976d2;
    border: 1px #1976d2 solid;
    border-radius: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
}
/* Background  */
.background-primary {
    background-color: #fff;
    
}
/* Text alignment */
.align-center{text-align:center;}
.align-right{text-align:right;}
.align-left{text-align:left;}

/* padding alignment */
.padding-top-bottom-10px{padding:10px 0;}
.padding-20px{padding:20px;}
.padding-left-right-20{padding : 0 20px}
/* text colors */

/* Active classes */
.activeNav{
    border-left: 5px #528ff0 solid!important;
    background-color:#252939!important;
}
/* MArgin */
.margin-top-20{
    margin-top: 20px;
}
.margin-top-50{
    margin-top: 50px;
}
.margin-right-6{
    margin-right: 6px!important;
}
/* Error code */
.error-404{
    color: red;
}
/* Card Color */
.card-primary{border-top: 2px solid #1976d2}
.card-secondary{border-top: 2px solid #dc3545}
.card-success{border-top: 2px solid #155724}
.card-default{border-top: 2px solid #6c757d}
/* Cursor */
.pointer{cursor: pointer}

/* Text Tramsform */
.uppercase{text-transform:uppercase}

/* Color */
.success{color:green}
.danger{color:red}
.waiting{color:orange}

/* Action button */
.action-button{padding: 0 15px!important}